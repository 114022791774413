import dbUtils from "@/modules/dbUtils";

export default {
    /**
     * Décoder le token
     * @param token
     * @return {string}
     **/
    decodeToken(token) {
        if (!token || token === '' || token === null)
            return false;

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

        const decodedData = JSON.parse(window.atob(base64));

        return decodedData;
    },

    /**
     * Convertir la date renvoyer par l'API (SQL DateTime)
     * @param date
     * @returns {string}
     */
    dateApiToJS(date) {
        if(date) {
            const dateObject = new Date(date);
            return dateObject.toISOString().split('T')[0];
        }
    },

    /**
     * Retourne la date en String pour l'historique => Le XX/XX/XXXX à XX:XX
     * @param dateString
     * @return {string}
     */
    formatDateHistorique(dateString) {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('fr-FR', options);
        const formattedTime = date.toLocaleTimeString('fr-FR', { hour: 'numeric', minute: 'numeric' });
        return `Le ${formattedDate}, à ${formattedTime}`;
    },

    /**
     * Retourne la date et l'heure actuelle au format SQL
     * @return {string}
     */
    getFormattedDateTime() {
        const now = new Date();

        function pad(number) {
            return (number < 10) ? "0" + number : number;
        }

        const year = now.getFullYear();
        const month = pad(now.getMonth() + 1);
        const day = pad(now.getDate());
        const hours = pad(now.getHours());
        const minutes = pad(now.getMinutes());
        const seconds = pad(now.getSeconds());

        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDateTime;
    },

    /**
     * Convert une datetime SQL en DD/MM/AAAA HH:MM:SS
     * @param sqlDatetime
     * @return {string}
     */
    formatSqlDatetime(sqlDatetime) {
        const date = new Date(sqlDatetime);

        if (isNaN(date.getTime())) {
            return 'Date invalide';
        }

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },

    /**
     * Retourne la date actuelle au format SQL
     * @return {string}
     */
    getFormattedDate() {
        const now = new Date();

        function pad(number) {
            return (number < 10) ? "0" + number : number;
        }

        const year = now.getFullYear();
        const month = pad(now.getMonth() + 1);
        const day = pad(now.getDate());

        const formattedDateTime = `${year}-${month}-${day}`;
        return formattedDateTime;
    },

    /**
     * Retourne la date en paramètre au format DD/MM/YYYY
     * @param dateString
     * @return {string}
     */
    formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

        return (formattedDate != '01/01/1970' ? formattedDate : '');
    },

    /**
     * Retourne la date du jour au format DD/MM/YYYY
     * @param dateString
     * @return {string}
     */
    todayDate() {
        const date = new Date();

        const jour = String(date.getDate()).padStart(2, '0');
        const mois = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0, donc on ajoute 1
        const annee = date.getFullYear();

        const dateEnString = `${jour}/${mois}/${annee}`;

        return dateEnString;
    },

    /**
     * Rendre un champ readOnly s'il est validé et que l'utilisateur n'est pas de Sogedis
     * @param statut
     * @param userRole
     * @return {Promise<boolean>}
     */
    async isReadOnly(statut, userRole) {
        const roleAllowed = dbUtils.roleFromSogedis.split(',').map(extension => parseInt(extension.trim().replace(/'/g, ''), 10));
        return !roleAllowed.includes(userRole);
    },

    /**
     * Empecher l'upload si l'accord est validé
     * @param statut
     * @param userRole
     * @return {Promise<boolean>}
     */
    async uploadReadOnly(statut) {
        // const roleAllowed = [1,2];
        let readOnly = true
        if(statut != 1 || statut != 4)
            readOnly = false;

        return readOnly;
    },

    /**
     * Forcer le rafraichissement d'un composant
     * @param component
     * @return {number}
     */
    forceRefresh(component) {
        return component += 1;
    },

    /**
     * Check si on est en version mobile
     * @return {boolean}
     */
    isMobileView() {
        if(window.innerWidth < 1280)
            return true;
        else
            return false;
    },

    /**
     * Check si l'accord appartient à l'utilisateur
     * @return {boolean}
     */
    canAccessTo(UserCodeClient, CodeClient) {
        return UserCodeClient === CodeClient;
    },

    /**
     * Retourne le type de mail au format lisible
     * @param type_mail
     * @return {string}
     */
    getFormatedTMType(type_mail) {
        let type = type_mail;

        if (type_mail === 'DOC_TRANSPORT')
            type = 'DOCUMENT TRANSPORT';
        else if (type_mail === 'CHANGEMENT_STATUT')
            type = 'CHANGEMENT DE STATUT';
        else if (type_mail === 'NOTIF_MESSAGE')
            type = 'NOTIFICATION CLIENT';
        else if (type_mail === 'RELANCE MAIL')
            type = 'RELANCE MAIL';
        else if (type_mail === 'ALERTE_SECURITE')
            type = 'ALERTE DE SECURITE';

        return type;
    }
}
