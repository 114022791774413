<template>
  <div class="MainCard">
     <v-container>
         <v-row
             no-gutters
             style="height: 150px;"
         >
             <v-col>
                 <v-container class="LoginCard">
                     <h1 class="pageTitle">SOGEDIS</h1>
                     <v-divider :thickness="2" class="border-opacity-50" style="margin-bottom: 20px; color: #333;"></v-divider>
                     <h3 class="pageSubTitle">Gestion des accords de retour et demande de réparation</h3>

                     <h3 class="FieldName">Nom d'utilisateur</h3>

                         <v-text-field
                             v-model="username"
                             placeholder="Nom d'utilisateur"
                             density="compact"
                             prepend-inner-icon="mdi-account-outline"
                             bg-color="#EEF8F87F" color="textfield"
                             class="TextFieldCustom"
                             required
                         ></v-text-field>

                         <h3 class="FieldName">Mot de passe</h3>


                         <v-text-field
                             v-model="password"
                             :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                             :type="visible ? 'text' : 'password'"
                             placeholder="Entrez votre mot de passe"
                             density="compact"
                             prepend-inner-icon="mdi-lock-outline"
                             bg-color="#EEF8F87F"
                             color="textfield"
                             class="TextFieldCustom"
                             @click:append-inner="visible = !visible"
                             v-on:keyup.enter="login"
                             required
                         ></v-text-field>

                         <!-- <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                             <a
                             class="text-caption text-decoration-none text-blue"
                             href="#"
                             rel="noopener noreferrer"
                             target="_blank"
                             >
                             Forgot login password?</a>
                         </div> -->

                         <v-btn class="FormButton" type="submit" block append-icon="mdi-login" @click="login">{{ loading ? 'Veuillez patienter...' : 'Se connecter' }}</v-btn>
                 </v-container>
             </v-col>
         </v-row>
     </v-container>
</div>

</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2';
const API_URL = process.env.VUE_APP_API_URL;

export default {
 data: () => ({
   visible: false,
   username: '',
   password: '',
   loading: false,
   emailValid: false,
   emailRules: [
     (value) => !!value || 'Veuillez saisir une adresse e-mail.',
     (value) => /.+@.+\..+/.test(value) || 'Veuillez saisir une adresse e-mail valide.',
   ],
 }),

 methods: {

   async login() {
     try {
       this.loading = true;
       document.body.style.cursor = 'wait';
       const response = await axios.post(API_URL + 'login/', {
         username: this.username,
         password: this.password,
       });

       const token = response.data.token;

       localStorage.setItem('token', token);
       this.loading = false;
       document.body.style.cursor = 'auto'
       document.location.href="/";

     } catch (error) {

         this.loading = false;
         document.body.style.cursor = 'auto'
         if(error.response.data.message === "C'est votre première connexion, vous allez être redirigé pour choisir un mot de passe") {
             this.$store.commit('setUserId', error.response.data.userId);
             Swal.fire({
                   icon: 'info',
                   title: 'Première connexion',
                   text: error.response.data.message,
               }).then((result) => {
               if (result.isConfirmed || result.dismiss === Swal.DismissReason.close) {
                 document.location.href = "/firstLogin";
               }
             });

         } else {
             Swal.fire({
                   icon: 'error',
                   title: 'Erreur',
                   text: error.response.data.message,
               });
         }

     }
   },
},
}
</script>

<style scoped>

.MainCard {
  /*background-image: url('https://images.unsplash.com/photo-1659878997289-b9ab5a0663b6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80') !important;*/
  background-image: url('@/assets/wallpaper.jpg');
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
}

.pageTitle {
  color: #333;
  margin: auto;
  margin-bottom: 20px;
}

.pageSubTitle {
  color: #333;
  margin: auto;
  margin-bottom: 50px;
  font-weight: 500;
}

.FormButton {
  min-width: 150px;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 8px );
  -webkit-backdrop-filter: blur( 8px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );

  color: #333;
  width: 100%;
  height: 50px;
}

.TextFieldCustom  {
  margin-bottom: 25px;
}

.FieldName {
  color: #333;
  font-weight: 500;
}

@media (max-width: 600px) {
  /* Styles spécifiques pour les smartphones */
  .Card {
    padding: 20px;
  }
}

.v-input--density-compact {
  --v-input-padding-top: 7px !important;
}

.v-field__input {
  min-height: 50px !important;
  height: 150px !important;
}



</style>
