import axios from "axios";
import utils from "@/modules/utils";
const API_URL = process.env.VUE_APP_API_URL;

const dbUtils = {
    roleFromSogedis: [],
    async init() {
        this.roleFromSogedis = await this.getConfigs('ROLE_FROM_SOGEDIS');

    },

    /**
     * Retourne le nom de l'utilisateur en fonction de son id
     * @param id
     * @return {Promise<string>}
     */
    getUsername(id) {
        return axios.get(API_URL + `user/getUserInfo/${id}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                RefreshToken: localStorage.getItem('refreshToken'),
            },
        })
        .then((response) => {
            return response.data.nom + ' ' + response.data.prenom;
        })
        .catch((error) => {
            console.log('Erreur lors de la récupération des informations de l\'utilisateur :', error);
            return null;
        });
    },

    /**
     * Retourne le dernier accord qui a été créé par l'utilisateur en paramètre
     * @param iduser
     * @return {Promise<void>}
     */
    async getLastAccord(iduser) {
        try {
            const response = await axios.get(API_URL + `accord/getLastAccord/` + iduser ,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });

            return this.newAccordId = response.data.id;

        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Retourne le dernier RMA qui a été créé par l'utilisateur en paramètre
     * @param iduser
     * @return {Promise<void>}
     */
    async getLastRMA(iduser) {
        try {
            const response = await axios.get(API_URL + `rma/getLastRMA/` + iduser ,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });

            return response.data.id;

        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Retourne tous les litiges
     * @return {Promise<void>}
     */
    async getAllLitiges() {
        try {
            const response = await axios.get(API_URL + `litige/getAllLitiges/`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Retourne tous les enseignes
     * @return {Promise<void>}
     */
    async getAllEnseigne() {
        try {
            const response = await axios.get(API_URL + `enseigne/getAllEnseignes/`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Retourne le libelle d'un litige avec son id
     * @param id
     * @return {Promise<axios.AxiosResponse<any>>}
     */
    async getLitige(id) {
        return axios.get(API_URL + `litige/getLitige/` + id, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        })
            .then(response => {
                if(response.data) return response.data
            })
            .catch(error => {
                console.error('Erreur lors de la connexion :', error);
                throw error;
            });
    },

    /**
     * Retourne la liste de tous les produits
     * @return {Promise<void>}
     */
    async getAllProduits() {
        try {
            const response = await axios.get(API_URL + `produit/getAllProduits`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Retourne un produit en fonction de son id
     * @param id
     * @return {Promise<axios.AxiosResponse<any>>}
     */
    async getProduit(id) {
        return axios.get(API_URL + `produit/getProduit/` + id, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        })
            .then(response => {
                if(response.data) return response.data
            })
            .catch(error => {
                console.error('Erreur lors de la connexion :', error);
                throw error;
            });
    },

    /**
     * Retourne un produit en fonction de son libelle
     * @param lib
     * @return {Promise<axios.AxiosResponse<any>>}
     */
    async getProduitWithLib(lib) {
        return axios.get(API_URL + `produit/getProduitWithLib/` + lib, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        })
            .then(response => {
                if(response.data) return response.data
            })
            .catch(error => {
                console.error('Erreur lors de la connexion :', error);
                throw error;
            });
    },

    /**
     * Récupérer un statut avec son id
     * @param statut
     * @return {Promise<void>}
     */
    async getStatut(statut) {
        try {
            const response = await axios.get(API_URL + `statut/getStatut/${statut}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data

        } catch (error) {
            console.error('Erreur lors de la récupération de votre utilisateur :', error);
        }
    },

    /**
     * Retourne tous les transporteurs
     * @return {Promise<any>}
     */
    async getAllTransporteurs() {
        try {
            const response = await axios.get(API_URL + `transporteur/getAllTransporteurs/`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Récupérer les informations d'un transporteur avec son id
     * @param id
     * @return {Promise<void>}
     */
    async getTransporteur(id) {
        try {
            const response = await axios.get(API_URL + `transporteur/getTransporteur/${id}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data

        } catch (error) {
            console.error('Erreur lors de la récupération de votre transporteur :', error);
        }
    },

    /**
     * Récupérer les informations d'une societé avec son id
     * @param id
     * @return {Promise<void>}
     */
    async getSociete(id) {
        try {
            const response = await axios.get(API_URL + `societe/getSociete/${id}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data

        } catch (error) {
            console.error('Erreur lors de la récupération de votre transporteur :', error);
        }
    },

    /**
     * Récupère tous les classements
     * @return {Promise<void>}
     */
    async getAllClassements() {
        try {
            const response = await axios.get(API_URL + `classement/getAllClassements/`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Retourne l'historique de l'accord
     * @param id
     * @param created
     * @return {Promise<string>}
     */
    async getCreatedUpdatedInfo(id, created) {
        try {
            if(id == null || id === '')
                return false;

            const response = await axios.get(API_URL + `user/getUserInfo/${id}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            if(created) {
                return "Créé par " + response.data.nom + " " + response.data.prenom;
            } else {
                return "Modifié par " + response.data.nom + " " + response.data.prenom;
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs Created et Modified ('+created+'):', error);
        }
    },

    /**
     * Retourne l'id d'un code client en fonction du Code Client
     * @param cc
     * @return {Promise<axios.AxiosResponse<any>>}
     */
    async getCCID(cc) {
        if(!cc)
            return false;

        return axios.get(API_URL + `client/getClientByCodeClient/` + cc, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        })
            .then(response => {
                if(response.data) return response.data.id
            })
            .catch(error => {
                console.error('Erreur lors de la connexion :', error);
                throw error;
            });
    },

    /**
     * Retourne l'id d'une station technique en fonction de la ST
     * @param cc
     * @return {Promise<axios.AxiosResponse<any>>}
     */
    async getSTID(st) {
        if(!st)
            return false;

        return axios.get(API_URL + `st/getSTeByST/` + st, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        })
            .then(response => {
                if(response.data) return response.data.id
            })
            .catch(error => {
                console.error('Erreur lors de la connexion :', error);
                throw error;
            });
    },

    /**
     * Retourne l'id d'un code client en fonction de l'id
     * @param id
     * @return {Promise<axios.AxiosResponse<any>>}
     */
    async getClient(id, object = false) {
        return axios.get(API_URL + `client/getClientById/` + id, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        })
            .then(response => {
                if(response.data) return (object ? response.data : response.data.id)
            })
            .catch(error => {
                console.error('Erreur lors de la connexion :', error);
                throw error;
            });
    },

    /**
     * Retourne tous les clients
     * @return {Promise<void>}
     */
    async getAllClients() {
        try {
            const response = await axios.get(API_URL + `client/getAllClients/`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Retourne tous les numéros de séries des accords
     * @return {Promise<void>}
     */
    async getAllNumSerie() {
        try {
            const response = await axios.get(API_URL + `accord/getAllNumSerie/`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Retourne toute les sociétés
     * @return {Promise<void>}
     */
    async getAllSocietes() {
        try {
            const response = await axios.get(API_URL + `societe/getAllSocietes/`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Récupère les messages en attente
     * @return {Promise<void>}
     */
    async getPendingMessages(token) {
        try {

            const tokenId = (utils.decodeToken(localStorage.getItem('token'))).id;
            const roles = this.roleFromSogedis.split(',').map(extension => parseInt(extension.trim().replace(/'/g, ''), 10));
            const userInfo = await dbUtils.getUserInfos(tokenId);
            let codeClient;

            if(userInfo.code_client) {
                codeClient = await dbUtils.getCCID(userInfo.code_client);
            }

            let url = API_URL + 'message/getMessagesNonLuFromClient';
            if (!roles.includes(token)) {
                if (codeClient)
                    url = API_URL + 'message/getMessagesNonLuFromSogedis/' + codeClient;
                else
                    url = API_URL + 'message/getMessagesNonLuFromSogedisForST/' + userInfo.id;
            }

            const response = await axios.get(url, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    RefreshToken: localStorage.getItem('refreshToken')
                },
            });
            return response.data.length;
        } catch (error) {
            console.log('Erreur lors de la récupération des messages :', error);
        }
    },

    /**
     * Récupère les messages en attente pour un accord
     * @return {Promise<void>}
     */
    async getPendingMessagesFromAccord(token, accordId, type = 'accord') {
        try {
            const roles = this.roleFromSogedis.split(',').map(extension => parseInt(extension.trim().replace(/'/g, ''), 10));

            let url = API_URL + 'message/getMessagesNonLuFromClientByIdLitige/' + accordId + '/' + type;

            if (!roles.includes(token)) {
                url = API_URL + 'message/getMessagesNonLuFromSogedisByIdLitige/' + accordId + '/' + type;
            }

            const response = await axios.get(url, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    RefreshToken: localStorage.getItem('refreshToken')
                },
            });
            return response.data.length;
        } catch (error) {
            console.log('Erreur lors de la récupération des messages :', error);
        }
    },

    /**
     * Retourne toutes les familles
     * @return {Promise<*[]>}
     */
    async getAllFamille() {
        try {
            const response = await axios.get(API_URL + `famille/getAllFamilles`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    RefreshToken: localStorage.getItem('refreshToken')
                },
            });
            return response.data;

        } catch (error) {
            console.log('Erreur lors de la récupération des familles :', error);
        }
    },

    /**
     * Retourne toutes les marques
     * @return {Promise<*[]>}
     */
    async getAllMarque() {
        try {
            const response = await axios.get(API_URL + `marque/getAllMarques`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    RefreshToken: localStorage.getItem('refreshToken')
                },
            });
            return response.data;

        } catch (error) {
            console.log('Erreur lors de la récupération des marques :', error);
        }
    },

    /**
     * Retourne toutes les stations techniques
     * @return {Promise<void>}
     */
    async getAllST() {
        try {
            const response = await axios.get(API_URL + `st/getAllST`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    RefreshToken: localStorage.getItem('refreshToken')
                },
            });
            return response.data;

        } catch (error) {
            console.log('Erreur lors de la récupération des st :', error);
        }
    },

    /**
     * Retourne tous les roles
     * @return {Promise<void>}
     */
    async getAllRole() {
        try {
            const response = await axios.get(API_URL + `role/getAllRole`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    RefreshToken: localStorage.getItem('refreshToken')
                },
            });
            return response.data;

        } catch (error) {
            console.log('Erreur lors de la récupération des roles :', error);
        }
    },

    /**
     * Retourne tous les statuts
     * @return {Promise<void>}
     */
    async getAllStatuts() {
        try {
            const response = await axios.get(API_URL + `statut/getAllStatut`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    RefreshToken: localStorage.getItem('refreshToken')
                },
            });
            return response.data;

        } catch (error) {
            console.log('Erreur lors de la récupération des statuts :', error);
        }
    },

    /**
     * Retourne tous les commentaires d'un accord
     * @return {Promise<void>}
     */
    async getAllCommentaireFromDistant(id, zone) {
        try {
            const response = await axios.get(API_URL + `commentaire/getAllCommentairesFromDistant/` + id + `/` + zone, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    RefreshToken: localStorage.getItem('refreshToken')
                },
            });
            return response.data;

        } catch (error) {
            console.log('Erreur lors de la récupération des commentaires :', error);
        }
    },

    /**
     * Crééer un commentaire pour un accord
     * @param id_accord
     * @param id_user
     * @param id_zone
     * @param action
     * @return {Promise<void>}
     */
    async createComment(id_accord, id_user, id_zone, action) {
        try {
            let commentaire;
            switch (action) {
                case 'valider_accord':
                    commentaire = "L'accord a été validé.";
                    break;

                case 'refuser_accord':
                    commentaire = "L'accord a été refusé.";
                    break;

                case 'creer_accord':
                    commentaire = "L'accord a été créé.";
                    break;

                case 'creer_rma':
                    commentaire = "La demande de réparation a été créée.";
                    break;

                case 'valider_rma':
                    commentaire = "La demande de réparation a été validée.";
                    break;

                case 'refuser_rma':
                    commentaire = "La demande de réparation a été refusée.";
                    break;

                case 'en_cours_rma':
                    commentaire = "La demande est en cours de traitement.";
                    break;

                case 'remettre_accord_attente':
                    commentaire = "L'accord a été remis en attente.";
                    break;

                case 'remettre_rma_attente':
                    commentaire = "Le RMA a été remis en attente.";
                    break;
            }

            await axios.post(API_URL + `commentaire/createCommentaire`, {
                id_user: id_user,
                id_zone: id_zone,
                id_distant: id_accord,
                commentaire: commentaire,
                date: utils.getFormattedDateTime()
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

        } catch (error) {
            console.log('Erreur lors de la création du commentaire :', error);
        }
    },

    /**
     * Récupère les informations d'un utilisateur
     * @param id
     * @return {Promise<any>}
     */
    async getUserInfos(id) {
        try {
            const response = await axios.get(API_URL + `user/getUserInfo/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });
            return response.data;

        } catch (error) {
            console.log('Erreur lors de la récupération de l\'utilisateur :', error);
        }
    },

    /**
     * Récupère la valeur d'une configuration
     * @param libelle
     * @return {Promise<*>}
     */
    async getConfigs(libelle) {
        try {
            const response = await axios.get(API_URL + `configuration/getConfig/${libelle}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });
            return response.data[0].valeur;

        } catch (error) {
            console.log('Erreur lors de la récupération de l\'utilisateur :', error);
        }
    },

    /**
     * Génère un nouveau numéro d'accord
     * @return {Promise<axios.AxiosResponse<any>>}
     */
    async getNewNumAccord() {
        try {
            const response = await axios.get(API_URL + `accord/getLastAccordForNumAccord`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });

            return response
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Génère un nouveau numéro de RMA
     * @return {Promise<axios.AxiosResponse<any>>}
     */
    async getNewNumRMA() {
        try {
            const response = await axios.get(API_URL + `rma/getLastRMAForNumRMA`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });

            return response
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Test si l'utilisateur a le role Constructeur
     * @return {Promise<boolean>}
     */
    async isConstructor() {
        try {
            const sToken = localStorage.getItem('token');
            if (!sToken)
                return false;

            const role = (utils.decodeToken(sToken)).role;
            const roleConstructeur = (await this.getConfigs('ROLE_CONSTRUCTEUR')).split(',').map(extension => extension.trim().replace(/'/g, '', 10));
            if (roleConstructeur.includes(role.toString()))
                return true;
            else
                return false;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
            return false;
        }
    },

    /**
     * Test si l'utilisateur a le role Admin
     * @return {Promise<boolean>}
     */
    async isAdmin() {
        try {
            const role = (utils.decodeToken(localStorage.getItem('token'))).role;
            const roleConstructeur = (await this.getConfigs('ROLE_ADMIN')).split(',').map(extension => extension.trim().replace(/'/g, '', 10));
            if (roleConstructeur.includes(role.toString()))
                return true;
            else
                return false;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
            return false;
        }
    },

    /**
     * Récupère l'id de la garantie via son numéro de série (Ancien portail)
     * @param numSerie
     * @return {Promise<any>}
     */
    async garantieAlreadyExist(numSerie) {
        try {
            const response = await axios.get(API_URL + `sogedisgaranties/getIdGarantieBySerial/${numSerie}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Récupère l'id de l'accord via son numéro de série (Ancien portail)
     * @param numSerie
     * @return {Promise<any>}
     */
    async accordsAlreadyExist(numSerie) {
        try {
            const response = await axios.get(API_URL + `sogedisaccords/getIdAccordBySerial/${numSerie}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Récupère la liste des produits qui font l'objet d'un accord
     * @return {Promise<void>}
     */
    async getListeProduitsRMA() {
        try {
            const response = await axios.get(API_URL + `produit/getAllProduitsFromRMA`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Récupère la liste des utilisateurs
     * @return {Promise<void>}
     */
    async getAllUser() {
        try {
            const response = await axios.get(API_URL + `user/getAllUser`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Récupère la liste des utilisateurs
     * @return {Promise<void>}
     */
    async getAllAccords() {
        try {
            const response = await axios.get(API_URL + `accord/getAllAccords`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Récupère la liste des utilisateurs
     * @return {Promise<void>}
     */
    async getAllRMA() {
        try {
            const response = await axios.get(API_URL + `rma/getAllRMA`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    },

    /**
     * Permet de notifier si le document transport a déjà été envoyé au transporteur
     * @param id
     * @return {Promise<*>}
     */
    async docTransportAlreadySent(id) {
        try {
            const response = await axios.get(API_URL + `trackingmail/getLogsDistant/${id}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            const bAlreadySent = response.data.some(item => item.type_mail === "DOC_TRANSPORT");
            return bAlreadySent
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
        }
    }
};
export default dbUtils;
