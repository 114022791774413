<template>
    <div style="display: flex; align-items: left; justify-content: left; margin: 10px 0 10px 10px;">
        <span style="padding: 0 10px; font-weight: 600; letter-spacing: 1px; color: rgb(161, 161, 161);">{{ title }}</span>
    </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style scoped>
.dividerMenu {
  flex: 1;
  color: white;
}
</style>