<template>
      <v-app-bar app :elevation="0" class="HeaderBar" density="compact" v-if="!isLoading">

        <v-toolbar-title class="AppName">Gestion des accords de retour et demande de réparation</v-toolbar-title>

        <v-btn class="showMenu" icon onclick="document.querySelector('.NavDrawerContainer').style.transform = (document.querySelector('.NavDrawerContainer').style.transform === 'translateX(0%)') ? 'translateX(-100%)' : 'translateX(0%)'">
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon to="/pendingMess">
          <v-icon>mdi-message-processing</v-icon>
          <span v-if="pendingMessages && pendingMessages > 0" class="badge">{{ pendingMessages }}</span>
        </v-btn>

        <v-btn icon to="/profil">
          <v-icon>mdi-account-cog</v-icon>
        </v-btn>

        <v-btn icon @click="logout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>

      </v-app-bar>


  </template>

  <script>
  import dbUtils from "@/modules/dbUtils";
  import utils from "@/modules/utils";

  export default {
    data() {
      return {
        drawerOpen: false,
        pendingMessages: null,
        isLoading: true
      };
    },
    async mounted() {
      const sToken = localStorage.getItem('token');
      if (!sToken || sToken === '' || sToken === null)
        return false;

      await dbUtils.init();
      let role = (utils.decodeToken(localStorage.getItem('token'))).role;
      this.pendingMessages = await dbUtils.getPendingMessages(role);
      if(role)
        this.isLoading = false;

      setInterval(async () => this.pendingMessages = await dbUtils.getPendingMessages((utils.decodeToken(localStorage.getItem('token'))).role), parseInt(await dbUtils.getConfigs('REFRESH_MESSAGE') ?? "60000")); // 600000 ms = 10 minutes
    },

    created() {
      this.$eventBus.on('reloadMessage', () => {this.pendingMessages = dbUtils.getPendingMessages((utils.decodeToken(localStorage.getItem('token'))).role)});
    },

    beforeUnmount() {
      this.$eventBus.off('reloadMessage', () => {this.pendingMessages = dbUtils.getPendingMessages((utils.decodeToken(localStorage.getItem('token'))).role)});
    },

    methods: {
      toggleDrawer() {
        this.drawerOpen = !this.drawerOpen;
      },

      logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('vuex');
        localStorage.removeItem('_secure__ls__metadata');
        document.location.href="/login";
      },
    },

  };
  </script>