<template>
    <div class="MainCard">
        <v-container>
            <v-row no-gutters style="height: 150px;">
                <v-col>
                    <v-container class="LoginCard">
                        <h1 class="pageTitle">Première connexion</h1>
                        <v-divider :thickness="2" class="border-opacity-50"
                            style="margin-bottom: 20px; color: #333;"></v-divider>
                        <h3 class="pageSubTitle">Vous devez choisir un mot de passe</h3>

                        <h3 class="FieldName">Mot de passe</h3>
                        <v-text-field v-model="password" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                            :type="visible ? 'text' : 'password'" placeholder="Entrez votre mot de passe"
                            prepend-inner-icon="mdi-lock-outline" bg-color="#EEF8F87F" color="textfield" class="TextFieldCustom" @click:append-inner="visible = !visible">

                        </v-text-field>

                        <h3 class="FieldName">Confirmation du mot de passe</h3>
                        <v-text-field v-model="confPassword" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                            :type="visible2 ? 'text' : 'password'" placeholder="Entrez votre mot de passe"
                            prepend-inner-icon="mdi-lock-outline" bg-color="#EEF8F87F" color="textfield" class="TextFieldCustom" @click:append-inner="visible2 = !visible2">

                        </v-text-field>

                        <v-btn class="FormButton" type="submit" block append-icon="mdi-login" @click="upatePassword">{{ loading ?
                            'Veuillez patienter...' : 'Modifier le mot de passe' }}</v-btn>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style scoped>
.MainCard {
  /*background-image: url('https://images.unsplash.com/photo-1659878997289-b9ab5a0663b6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80') !important;*/
  background-image: url('@/assets/wallpaper.jpg');
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
}

.pageTitle {
    color: #333;
    margin: auto;
    margin-bottom: 20px;
}

.pageSubTitle {
    color: #333;
    margin: auto;
    margin-bottom: 50px;
    font-weight: 500;
}

.FormButton {
    min-width: 150px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    color: #333;
    width: 100%;
    height: 50px;
}

.TextFieldCustom {
    margin-bottom: 25px;
}

.FieldName {
    color: #333;
    font-weight: 500;
}

@media (max-width: 600px) {

    /* Styles spécifiques pour les smartphones */
    .Card {
        padding: 20px;
    }
}
</style>


<script>
import axios from 'axios'
import Swal from 'sweetalert2';
import utils from "@/modules/utils";
const API_URL = process.env.VUE_APP_API_URL;

export default {
    data: () => ({
        userId: null,
        visible: false,
        visible2: false,
        password: '',
        confPassword: '',
        loading: false,
    }),

    mounted() {
        this.userId = this.$store.state.userId;
    },

    methods: {

        async upatePassword() {
            try {
                this.loading = true;
                document.body.style.cursor = 'wait';

              const regexUpperCase = /[A-Z]/;
              const regexLowerCase = /[a-z]/;
              const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
              const minLength = 8;

              if (!regexUpperCase.test(this.password) ||
                  !regexLowerCase.test(this.password) ||
                  !regexSpecialChar.test(this.password) ||
                  this.password.length < minLength) {
                this.loading = false;
                document.body.style.cursor = 'auto'
                Swal.fire({
                  icon: 'error',
                  title: 'Erreur',
                  text: "Le mot de passe doit contenir au moins 1 lettre majuscule, 1 lettre minuscule, 1 caractère spécial et être d'au moins 8 caractères de longueur."
                });
                return false;
              }

                const response = await axios.put(API_URL + `login/updateFirstPassword/${this.userId}`, {
                    password: this.password,
                    confPassword: this.confPassword,
                    updatedBy: this.userId,
                    updatedAt: utils.getFormattedDateTime(),
                    firstLogin: 0,
                    isActive: 1
                });

                const token = response.data.token;

                localStorage.setItem('token', token);
                this.loading = false;
                document.body.style.cursor = 'auto'
                document.location.href = "/";

            } catch (error) {
                this.loading = false;
                document.body.style.cursor = 'auto'
                Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: error.response.data.error,
                    });

            }
        },
    },
}
</script>
