<template>
  <LoginView v-if = "currentPath === '/login'"/>
  <FirstLoginView v-else-if = "currentPath === '/firstLogin'"/>
  <HomeView v-else/>
</template>

<script>

import HomeView from "./views/HomeView.vue";
import LoginView from "./views/LoginView.vue";
import FirstLoginView from "./views/FirstLoginView.vue";

export default {
  name: 'App',
    components: {HomeView, LoginView, FirstLoginView},

  data: () => ({
    currentPath: ''
  }),
  created() {
    this.currentPath = window.location.pathname;
  },

}
</script>

<style>
body {
  font-family: 'Montserrat', sans-serif !important;
}

.video-bg {
 position: fixed;
 right: 0;
 top: 0;
 width: 100%;
 height: 100%;
}


.video-bg video {
  width: 100%;
  height: 100%;
  object-fit: cover;
 }

</style>
