// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const appTheme = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        surface: '#FFF',
        primary: '#00aae8',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#ff0000', //B00020
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        // hover: '#484848',
        hover: '#ffffff',
        icon: '#00baff',
        textfield: '#dedede',
        //textfieldBg: '#1d213f',
        textfieldBg: '#5E82B8',
        loginTF: '#333',
        loginBg: '#EEF8F87F',
        cardBg : '#506BB8',
    },
}

export default createVuetify({
    theme: {
        defaultTheme: 'appTheme',
        themes: {
            appTheme,
        },
    },
    }
)
